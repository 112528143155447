import { queryTypes, useQueryStates } from "next-usequerystate";

import {
  FreightTypeOption,
  OrderOption,
  SortOption,
  TransitTypeOption,
} from "@sellernote/_shared/src/types/forwarding/schedule";
import {
  addDate,
  getAppTodayMidnight,
} from "@sellernote/_shared/src/utils/common/date";

export default function useScheduleSearchBarQueryStates() {
  const [state, setState] = useQueryStates({
    freightType: queryTypes
      .stringEnum<FreightTypeOption>(Object.values(FreightTypeOption))
      .withDefault(FreightTypeOption.FCL),
    polId: queryTypes.integer,
    podId: queryTypes.integer,
    etd: queryTypes.string.withDefault(
      addDate({
        date: getAppTodayMidnight(),
        value: 3,
        unit: "day",
      }).toISOString()
    ),
    eta: queryTypes.string,

    page: queryTypes.integer.withDefault(0),

    minTransitDay: queryTypes.integer,
    maxTransitDay: queryTypes.integer,
    filterPodIds: queryTypes.array(queryTypes.integer),
    filterPolIds: queryTypes.array(queryTypes.integer),
    transit: queryTypes.stringEnum<TransitTypeOption>(
      Object.values(TransitTypeOption)
    ),
    linerIdList: queryTypes.array(queryTypes.integer),

    sort: queryTypes
      .stringEnum<SortOption>(Object.values(SortOption))
      .withDefault(SortOption.Cost),
    order: queryTypes
      .stringEnum<OrderOption>(Object.values(OrderOption))
      .withDefault(OrderOption.Asc),

    /**
     * portGroupId는 스케줄 조회 결과가 없는 경우 견적조회 페이지로 이동 시
     * 스케줄 조회 페이지에만 존재하는 출발/도착지(e.g. 부산신항)를 조회하기 위한 쿼리 파라미터
     */
    polPortGroupId: queryTypes.integer,
    podPortGroupId: queryTypes.integer,

    /**
     * SearchBar의 '조회' 버튼으로 검색한 것인지 여부
     *  - true인 경우에는 비활성화 처리된 Filter를 로딩 컴포넌트처럼 사용한다.
     */
    isSearchBar: queryTypes.boolean.withDefault(true),

    /**
     * 모바일에서 데이터 조회 시 스크롤이 필요한 경우
     */
    needsAutoScroll: queryTypes.boolean,
  });

  return [
    state,
    (newState: Partial<typeof state>) => setState(newState, { shallow: true }),
  ] as const;
}
