import styled from "styled-components";

import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const submenuItem = styled.li`
  > a {
    display: flex;
    gap: 8px;
    padding: 4px 0;
    cursor: pointer;
  }

  > a:hover {
    .label,
    .desc {
      color: ${COLOR.primary_600};
    }
  }

  > a:visited {
    color: unset;
    text-decoration: none;
  }
`;

/** 메뉴 상단 삼각형 꼭지점(arrow-block) 형태 변경으로 아이콘이 가려지는 이슈가 있어 z-index 추가 */
const iconBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 104;
`;

const contentsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  .desc {
    ${setFontStyle("Body3")};
    color: ${TEXT_COLOR.black_3};
  }
`;

const labelAndBadges = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  .label {
    ${setFontStyle("SubHead3")};
    color: ${TEXT_COLOR.black_2};
  }
`;

const badges = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  .custom-badge {
    height: 18px;
  }
`;

export default {
  submenuItem,
  iconBlock,
  contentsBlock,
  labelAndBadges,
  badges,
};
