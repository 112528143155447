import styled from "styled-components";

import { COLOR } from "../../styles/colors";
import { setFontStyle } from "../../styles/typography";

import { loadingDirection } from ".";

const container = styled.div<{
  direction: loadingDirection;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: center;
  gap: ${({ direction }) => (direction === "row" ? "10px" : "8px")};

  > svg {
    --uib-size: 24px;
    --uib-color: ${COLOR.point_400};
    --uib-speed: 2s;

    height: var(--uib-size);
    width: var(--uib-size);
    vertical-align: middle;
    transform-origin: center;
    animation: rotate var(--uib-speed) linear infinite;
    will-change: transform;

    > circle {
      fill: none;
      stroke: var(--uib-color);
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      animation: stretch calc(var(--uib-speed) * 0.75) ease-in-out infinite;
      will-change: stroke-dasharray, stroke-dashoffset;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes stretch {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
      }
      100% {
        stroke-dashoffset: -124px;
      }
    }
  }
`;

const message = styled.div<{ color: string }>`
  user-select: none;
  ${setFontStyle("SubHead4")};
  color: ${({ color }) => color};
`;

export default {
  container,
  message,
};
