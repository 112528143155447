import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setElevationStyle } from "@sellernote/_sds-v2/src/styles/elevation";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const category = styled.span`
  padding: 8px 12px 0px;
  word-break: keep-all;

  span.label {
    ${setFontStyle("SubHead4")};
    color: ${COLOR.secondary_400};
    white-space: nowrap;
  }

  p.desc {
    display: inline;
    margin-left: 4px;
    ${setFontStyle("Body4")};
    color: ${TEXT_COLOR.black_3};
  }
`;

const categoryPortOptionItem = styled.div`
  display: flex;
  flex-direction: column;

  & > ul.port-option-list {
    display: flex;
    flex-direction: column;
  }

  svg {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
  }
`;

const portOptionItem = styled.li`
  display: flex;
  padding: 10px 12px;
  gap: 8px;
  ${setFontStyle("Body2")};
  color: ${TEXT_COLOR.black_2};
  cursor: pointer;

  &:hover {
    background-color: ${COLOR.primary_50};
  }
`;

const emptySearchResult = styled.div<{ width: string }>`
  z-index: 1;
  ${setElevationStyle("level3")};

  display: flex;
  align-items: center;
  gap: 10px;
  padding: 18px 16px;
  background: ${COLOR.wh};
  ${({ width }) =>
    css`
      width: ${width};
    `}
  ${setFontStyle("SubHead4")};
  color: ${TEXT_COLOR.black_disabled};
`;

export default {
  category,
  categoryPortOptionItem,
  portOptionItem,
  emptySearchResult,
};
