import { useState } from "react";
import { useTranslation } from "next-i18next";
import { useRecoilValue } from "recoil";
import { css } from "styled-components";

import useClickOutsideOfElement from "@sellernote/_shared/src/hooks/common/useClickOutsideOfElement";
import TEAM_QUERY from "@sellernote/_shared/src/queries/forwarding/TEAM_QUERY";
import USER_QUERY from "@sellernote/_shared/src/queries/forwarding/USER_QUERY";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import BottomSheet from "@sellernote/_sds-v2/src/components/bottomSheet/BottomSheet";
import UserPlusIcon from "@sellernote/_sds-v2/src/components/svgIcons/UserPlusIcon";
import Tooltip from "@sellernote/_sds-v2/src/components/Tooltip";

import UserIcon from "../..//UserIcon";
import useLogout from "../Logout/useLogout";
import AccountInfo from "./AccountInfo";
import ChatButton from "./ChatButton";
import CreateTeam from "./CreateTeam";
import CurrentAccountInfo from "./CurrentAccountInfo";
import Styled from "./index.styles";

export default function User({
  isMyPage,
  isTransparentBackground,
}: {
  isMyPage?: boolean;
  isTransparentBackground?: boolean;
}) {
  const { t } = useTranslation(["common-new", "containers"]);

  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const [showsAccountInfo, setShowsAccountInfo] = useState<boolean>(false);

  const [opensCreateTeam, setOpensCreateTeam] = useState(false);

  const {
    data: userInfo,
    ResponseHandler: ResponseHandlerOfGetUserInfo,
    isLoading: isLoadingOfUserInfo,
  } = USER_QUERY.useGetUserInfo({
    enabled: Boolean(loggedIn),
    hidesLoading: true,
  });

  const {
    data: accountData,
    isLoading: isLoadingOfAccountInfo,
    ResponseHandler: ResponseHandlerOfAccountInfo,
  } = TEAM_QUERY.useGetAccountInfo({
    hidesLoading: true,
  });

  const { company, name, email } = userInfo || {};

  const currentAccountInfo = accountData?.located;

  const companyOrTeamName = currentAccountInfo?.isPrivate
    ? currentAccountInfo?.company
    : currentAccountInfo?.name;

  const { isMobile } = useCheckIsMobile();

  const { ConfirmLogoutModal, handleConfirmLogoutModalOpen } = useLogout();

  const handleAccountInfoToggle = () => setShowsAccountInfo(!showsAccountInfo);

  const handleAccountInfoClose = () => setShowsAccountInfo(false);

  const { targetElementRef } = useClickOutsideOfElement({
    onClickOutside: handleAccountInfoClose,
  });

  return (
    <Styled.container ref={targetElementRef}>
      {isMyPage && !isMobile && <ChatButton />}

      <Styled.userInfoAndIconContainer>
        {currentAccountInfo && (
          <CurrentAccountInfo
            {...{ currentAccountInfo, isMyPage, isTransparentBackground }}
          />
        )}

        {isMobile && (
          <>
            <UserIcon
              id={currentAccountInfo?.id}
              name={companyOrTeamName}
              size={32}
              fontStyle="SubHead4"
              handleClick={handleAccountInfoToggle}
            />
          </>
        )}

        {!isMobile && (
          <>
            <Tooltip
              position="topRight"
              desc={
                <>
                  {company}
                  <br />
                  {name}
                  <br />
                  {email}
                </>
              }
              tooltipBodyStyle={css`
                top: 60px;
              `}
              disabled={isLoadingOfAccountInfo || isLoadingOfUserInfo}
            >
              <UserIcon
                id={currentAccountInfo?.id}
                name={companyOrTeamName}
                size={32}
                fontStyle="SubHead4"
                handleClick={handleAccountInfoToggle}
              />
            </Tooltip>
          </>
        )}
      </Styled.userInfoAndIconContainer>

      {showsAccountInfo &&
        (isMobile ? (
          <BottomSheet
            mainButtonInfo={{
              label: t("common-new:마이페이지_헤더_팀생성_버튼"),
              onClick: () => setOpensCreateTeam(true),
              theme: "tertiary",
              borderType: "outlined",
              disabled: false,
              iconInfo: {
                Icon: UserPlusIcon,
                position: "left",
              },
            }}
            subButtonInfo={{
              label: t("containers:Layout.로그아웃"),
              onClick: handleConfirmLogoutModalOpen,
              theme: "critical",
              disabled: false,
            }}
            opensBottomSheet={true}
            setOpensBottomSheet={() => {}}
            onClose={handleAccountInfoClose}
          >
            <AccountInfo
              accountData={accountData}
              isLoadingOfAccountInfo={isLoadingOfAccountInfo}
              ResponseHandlerOfAccountInfo={ResponseHandlerOfAccountInfo}
              closeAccountInfo={handleAccountInfoClose}
              openCreateTeam={() => setOpensCreateTeam(true)}
              isMyPage={isMyPage}
            />
          </BottomSheet>
        ) : (
          <AccountInfo
            accountData={accountData}
            isLoadingOfAccountInfo={isLoadingOfAccountInfo}
            ResponseHandlerOfAccountInfo={ResponseHandlerOfAccountInfo}
            closeAccountInfo={handleAccountInfoClose}
            openCreateTeam={() => setOpensCreateTeam(true)}
            isMyPage={isMyPage}
          />
        ))}

      {opensCreateTeam && (
        <CreateTeam
          opensCreateTeam={opensCreateTeam}
          setOpensCreateTeam={(v) => setOpensCreateTeam(v)}
          closeCreateTeamModal={() => setOpensCreateTeam(false)}
        />
      )}

      {ConfirmLogoutModal}

      {ResponseHandlerOfGetUserInfo}
    </Styled.container>
  );
}
