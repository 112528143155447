import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setElevationStyle } from "@sellernote/_sds-v2/src/styles/elevation";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div<{ isMyPage?: boolean }>`
  position: absolute;
  top: 60px;
  right: 8px;

  ${mobile(css`
    position: static;
  `)};
`;

const accountInfoContainer = styled.div`
  width: 320px;
  height: auto;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  background-color: ${COLOR.wh};
  border-radius: 8px;

  ${setElevationStyle("level2")};

  ${mobile(css`
    width: 100%;
    box-shadow: none;
    overflow: visible;
    height: auto;
  `)};
`;

const arrowBlock = styled.div<{ isMyPage: boolean }>`
  ${mobile(css`
    display: none;
  `)};

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: -1px;
    ${({ isMyPage }) => {
      if (isMyPage)
        return css`
          right: 24px;
        `;

      return css`
        left: 174px;
      `;
    }}
    width: 16px;
    height: 16px;
    background: ${COLOR.wh};
    transform: rotate(45deg);
    border-radius: 2px;
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: -1px;
    ${({ isMyPage }) => {
      if (isMyPage)
        return css`
          right: 24px;
        `;

      return css`
        left: 174px;
      `;
    }}
    width: 16px;
    height: 16px;
    background: transparent;
    transform: rotate(45deg);
    ${setElevationStyle("level2")};
    z-index: -1;
    border-radius: 2px;
  }
`;

const loggedInAccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px 24px 24px;
  border-bottom: 1px solid ${COLOR.grayScale_200};
  text-align: center;

  ${mobile(css`
    margin: 0 -16px;
    padding: 0 24px 24px 24px;
  `)};
`;

const accountName = styled.div`
  ${setFontStyle("SubHead1")};
  color: ${TEXT_COLOR.black_1};
  margin-top: 16px;
  margin-bottom: 4px;

  ${mobile(css`
    margin-top: 12px;
  `)};
`;

const accountDetail = styled.div`
  ${setFontStyle("Body2")};
  color: ${TEXT_COLOR.black_3};
  margin-bottom: 16px;

  ${mobile(css`
    margin-bottom: 12px;
  `)};
`;

const accountList = styled.ul`
  margin-top: 8px;
`;

const accountItem = styled.li`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  cursor: pointer;

  ${mobile(css`
    padding: 10px 8px;

    .user-icon {
      width: 40px;
      height: 40px;
      ${setFontStyle("SubHead4")};
    }
  `)};

  .account-info-container {
    margin-left: 16px;
  }

  .account-detail-container {
    display: flex;
    align-items: center;
  }

  .account-type {
    ${setFontStyle("Body3")};
    color: ${TEXT_COLOR.black_3};
  }

  .account-master-info {
    ${setFontStyle("Body4")};
    color: ${TEXT_COLOR.black_3};
  }

  .account-name {
    margin-top: 4px;
    ${setFontStyle("SubHead3")};
    color: ${TEXT_COLOR.black_1};
  }
`;

const paginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 0;

  ${mobile(css`
    margin-top: 12px;
  `)};
`;

const accountButtonContainer = styled.div`
  display: flex;
  padding: 16px;

  /* 기존 normal 버튼의 기본 width, padding 이 달라 커스텀 개별설정 */
  .logout {
    margin-right: 16px;

    button {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export default {
  container,
  accountInfoContainer,
  arrowBlock,
  loggedInAccountInfo,
  accountName,
  accountDetail,
  accountList,
  accountItem,
  paginationWrapper,
  accountButtonContainer,
};
