import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyleV1 } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

import { InputCheckBoxShape } from ".";

interface ContainerProps {
  shape: InputCheckBoxShape;
  color: string;
  checked?: boolean;
  disabled?: boolean;
  hasDesc: boolean;
}
const container = styled.div`
  cursor: pointer;

  ${(props: ContainerProps) => {
    if (props.hasDesc) {
      return css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0.5rem;
        border-radius: 5px;
        background-color: ${props.disabled
          ? COLOR.grayScale_50
          : COLOR.primaryBlue_10};
      `;
    } else {
      return css`
        display: inline-block;
      `;
    }
  }}

  ${(props: ContainerProps) => {
    if (props.disabled) {
      return css`
        cursor: not-allowed;
      `;
    }
  }}

  > .label-container {
    display: flex;
    justify-content: center;
    align-items: center;

    > .icon {
      margin-right: 0.25rem;
    }

    > .label {
      ${setFontStyleV1("SubHead3", "Bold")};
      color: ${(props: ContainerProps) => props.color};
    }
  }

  > .desc {
    margin-top: 0.25rem;
    ${setFontStyleV1("Body8")};
    color: ${(props: ContainerProps) =>
      props.disabled ? COLOR.grayScale_300 : COLOR.grayScale_400};
    text-align: left;
  }
`;

export default {
  container,
};
