import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const title = styled.div`
  ${setFontStyle("Head6")};

  ${mobile(css`
    ${setFontStyle("SubHead3")};
  `)}
`;

const subtitle = styled.span`
  ${setFontStyle("Body3")};
`;

const management = styled.div`
  padding: 16px 0px;

  ${mobile(css`
    padding: 0px;
  `)}
`;

export default {
  title,
  container,
  subtitle,
  management,
};
