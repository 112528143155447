import { CSSProperties } from "react";
import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

import { TabAlign, TabDirection, TabUiType } from ".";

const container = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * <overflow관련 디자이너와의 협의내용>
 * - 가로 넘침의 경우 모바일은 공통적으로 가로 스크롤 적용함
 * - 데스크탑은 실제 사용 필요한 것들의 스타일만 넣어줌(기본 상하 간격 16px)
 * - 툴팁이 들어가는 경우를 위해 overflow props 추가 (기본 "auto")
 */
const tabList = styled.div<{
  uiType: TabUiType;
  direction: TabDirection;
  overflowStyle: CSSProperties["overflow"];
  hasBorder?: boolean;
  align?: TabAlign;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: ${({ overflowStyle }) => overflowStyle};
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* 데스크탑 overflow때의 row간 gap용  */
  row-gap: 8px;

  ${mobile(css`
    margin: 0;
  `)}

  ${({ direction, align }) => {
    if (direction === "vertical")
      return css`
        /* button이 margin 8px 0을 가지기 때문에 vertical에서는 row-gap이 필요없음 (기본 상하 16px) */
        row-gap: 0;
        flex-direction: column;
        align-items: ${align === "right" ? "flex-end" : "center"};
        justify-content: center;
        padding: 0;
        margin: 0;

        > button {
          width: auto;
        }
      `;

    return css`
      padding: 8px;
      flex-direction: row;
      flex-wrap: wrap;

      ${mobile(css`
        flex-wrap: nowrap;
      `)}
    `;
  }};

  ${({ uiType, direction, hasBorder }) => {
    switch (uiType) {
      case "lined": {
        return css`
          /* underline 효과때문에 추가 */
          padding-bottom: 8px;
        `;
      }
      case "rounded": {
        return css`
          justify-content: flex-start;
          margin-left: 0;
          padding: 0;
        `;
      }

      case "text": {
        return css`
          padding: 0;

          ${mobile(css`
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            padding: 0;
            margin: -10px 0;
          `)}
        `;
      }

      case "file": {
        return css`
          align-items: flex-end;
          padding: 0 8px;
          box-shadow: 0 -1px 0 0 inset ${COLOR.grayScale_400};
        `;
      }

      case "boxDark": {
        return css`
          border: 1px solid ${COLOR.grayScale_700};
          padding: 4px;
          border-radius: 4px;
        `;
      }

      case "box": {
        if (direction === "vertical") {
          return css`
            padding: 16px 8px;
            gap: 16px;
          `;
        }

        if (hasBorder) {
          return css`
            border: 1px solid ${COLOR.grayScale_400};
            border-radius: 8px;
          `;
        }
      }
    }
  }};
`;

const tabItem = styled.button<{
  uiType: TabUiType;
  direction: TabDirection;
  isStretch?: boolean;
}>`
  flex-shrink: 0;

  & > .icon {
    align-self: center;

    &.left {
      margin-right: 4px;
    }

    &.right {
      margin-left: 4px;
    }
  }

  ${mobile(css`
    margin-top: 0;
    margin-bottom: 0;
  `)}

  ${({ isStretch }) =>
    isStretch &&
    css`
      flex-grow: 1;
    `}

  ${({ uiType, direction }) => {
    switch (uiType) {
      case "box": {
        return css`
          ${setFontStyle("SubHead3")};
          padding: 8px 16px;
          background-color: transparent;
          border-radius: 8px;
          text-align: center;
          color: ${TEXT_COLOR.black_2};
          cursor: pointer;
          border: 0;

          ${mobile(css`
            margin-bottom: 0;
          `)}

          &:hover:enabled {
            background-color: ${COLOR.grayScale_50};
            color: ${TEXT_COLOR.black_2};
          }

          &.selected,
          &.selected:hover:enabled {
            color: ${COLOR.wh};
            background-color: ${COLOR.grayScale_600};
          }

          &:disabled {
            color: ${TEXT_COLOR.black_disabled};
            cursor: not-allowed;
          }
        `;
      }

      case "boxDark": {
        return css`
          ${setFontStyle("SubHead4")};
          padding: 4px 8px;
          background-color: transparent;
          border-radius: 4px;
          text-align: center;
          color: ${TEXT_COLOR.white_2};
          cursor: pointer;
          border: 0;

          ${mobile(css`
            margin-bottom: 0;
          `)}

          &:hover:enabled {
            background-color: ${COLOR.primary_400};
            color: ${TEXT_COLOR.white_2};
          }

          &.selected,
          &.selected:hover:enabled {
            color: ${TEXT_COLOR.white_1};
            background-color: ${COLOR.primary_600};
          }

          &:disabled {
            color: ${TEXT_COLOR.white_disabled};
            cursor: not-allowed;
          }
        `;
      }

      case "rounded": {
        return css`
          ${setFontStyle("SubHead3")};
          padding: 4px 15px;
          background-color: transparent;
          border: 1px solid ${COLOR.grayScale_500};
          border-radius: 100px;
          color: ${COLOR.grayScale_700};
          text-align: center;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 16px;
          }

          &:hover:enabled {
            color: ${COLOR.grayScale_500};
            border: 1px solid ${COLOR.grayScale_500};
            background-color: ${COLOR.bk_4};
          }

          &.selected,
          &.selected:hover:enabled {
            color: ${COLOR.wh};
            border: 1px solid ${COLOR.grayScale_600};
            background-color: ${COLOR.grayScale_600};
          }

          &:disabled {
            color: ${COLOR.grayScale_400};
            border: 1px solid ${COLOR.grayScale_400};
            background-color: ${COLOR.grayScale_50};
            cursor: not-allowed;
          }
        `;
      }

      case "text": {
        return css`
          ${setFontStyle("SubHead4")};
          padding: 1px 16px;
          background-color: transparent;
          color: ${TEXT_COLOR.black_3};
          text-align: center;
          cursor: pointer;
          border: none;
          position: relative;
          margin: 10px 9px 10px 8px;

          ${mobile(css`
            margin: 10px 8px;
            padding: 1px 13px;
          `)}

          &:not(:last-child) {
            > .divider {
              position: absolute;
              background-color: ${COLOR.grayScale_400};
              top: 0;
              right: -9px;
              width: 1px;
              height: 22px;
            }
          }

          &:hover:enabled {
            color: ${COLOR.grayScale_700};
            background-color: ${COLOR.bk_4};
          }

          &.selected,
          &.selected:hover:enabled {
            color: ${TEXT_COLOR.black_1};
          }

          &:disabled {
            color: ${COLOR.grayScale_300};
            cursor: not-allowed;
          }
        `;
      }

      case "radioCheck": {
        return css`
          position: relative;
          display: inline-flex;
          align-items: center;
          padding: 0;
          ${setFontStyle("SubHead3")};
          background-color: transparent;
          border: none;
          color: ${COLOR.grayScale_400};
          text-align: center;
          cursor: pointer;

          > .radio-icon {
            display: inline-flex;
            margin-right: 4px;
          }

          ${direction === "horizontal" &&
          css`
            &:not(:last-child) {
              margin-right: 16px;
            }
          `}

          &.selected,
          &.selected:hover:enabled {
            color: ${COLOR.primary_600};
          }

          &:disabled {
            color: ${COLOR.grayScale_300};
            cursor: not-allowed;
          }
        `;
      }

      case "lined": {
        return css`
          ${setFontStyle("SubHead3")};
          padding: 0;
          margin: 0;
          background-color: transparent;
          color: ${COLOR.grayScale_500};
          text-align: center;
          cursor: pointer;
          border: none;
          position: relative;
          display: inline-flex;

          > .upper-dot {
            width: 4px;
            height: 4px;
            margin-top: 3px;
            margin-left: 4px;
            border-radius: 50%;
            background-color: ${COLOR.point_400};
          }

          > .underline {
            position: absolute;
            bottom: -8px;
            left: 0;
            height: 2px;
            width: 100%;
            background-color: transparent;
          }

          &:not(:last-child) {
            margin-right: 24px;
          }

          &:hover:enabled {
            color: ${COLOR.grayScale_500};
            background-color: ${COLOR.bk_4};
          }

          &.selected,
          &.selected:hover:enabled {
            color: ${COLOR.grayScale_800};

            > .underline {
              background-color: ${COLOR.grayScale_800};
            }
          }

          &:disabled {
            color: ${COLOR.grayScale_300};
            cursor: not-allowed;
          }
        `;
      }

      case "file": {
        return css`
          ${setFontStyle("SubHead4")};
          padding: 8px 12px 6px;
          background-color: ${COLOR.grayScale_100};
          border-radius: 4px 4px 0px 0px;
          text-align: center;
          color: ${TEXT_COLOR.black_4};
          cursor: pointer;
          border-top: 1px solid ${COLOR.grayScale_300};
          border-right: 1px solid ${COLOR.grayScale_300};
          border-left: 1px solid ${COLOR.grayScale_300};
          border-bottom: 1px solid ${COLOR.grayScale_400};

          ${mobile(css`
            margin-bottom: 0;
          `)}

          &:hover:enabled {
            background-color: ${COLOR.grayScale_200};
            color: ${TEXT_COLOR.black_4};
          }

          &.selected,
          &.selected:hover:enabled {
            background-color: ${COLOR.wh};
            color: ${TEXT_COLOR.black_2};
            padding: 12px 12px 8px;
            border-top: 1px solid ${COLOR.grayScale_400};
            border-right: 1px solid ${COLOR.grayScale_400};
            border-left: 1px solid ${COLOR.grayScale_400};
            border-bottom: 1px solid ${COLOR.wh};
          }

          &:disabled {
            color: ${TEXT_COLOR.black_disabled};
            background-color: ${COLOR.grayScale_100};
            border-top: 1px solid ${COLOR.grayScale_200};
            border-right: 1px solid ${COLOR.grayScale_200};
            border-left: 1px solid ${COLOR.grayScale_200};
            cursor: not-allowed;
          }
        `;
      }
    }
  }};
`;

const contentsContainer = styled.div`
  margin-top: 20px;
`;

export default {
  container,
  tabList,
  tabItem,
  contentsContainer,
};
