import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyleV1 } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;

  .modal > div,
  .modal > div .body,
  .login-in-progress {
    overflow: visible;
  }

  .custom-login-modal > div:first-child {
    width: 800px;
    padding: 0;
    background: #fcfcfc;
    box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.12);
    border-radius: 16px;

    ${mobile(css`
      border-radius: 5px;
    `)};
  }

  .custom-login-modal > div .body {
    margin-top: 0;
  }

  .custom-login-modal > div .icon-clear {
    top: 24px;
    right: 16px;
    width: 38px !important;
    height: 38px !important;

    ${mobile(css`
      top: 7px;
      right: 7px;
      width: 30px !important;
      height: 30px !important;
    `)};
  }

  &.find-password-modal .icon-clear {
    display: none;
  }

  &.find-password-modal .input-text {
    margin-top: 24px;

    ${mobile(css`
      margin-top: 32px;
    `)};
  }

  &.find-password-modal .button {
    margin: 64px 0 29px 0;

    ${mobile(css`
      margin: 40px 0 37px 0;
    `)};
  }

  &.find-password-modal form {
    padding: 40px 24px 80px 40px;

    ${mobile(css`
      padding: 12px 8px 86px 8px;
    `)};
  }

  .find-password-success-modal,
  .find-password-error-modal {
    > div:first-child {
      width: 568px;

      ${mobile(css`
        padding-left: 8px;
        padding-right: 8px;
      `)};
    }

    .header {
      margin-bottom: 24px;
    }

    .body {
      margin-bottom: 64px;
    }
  }
`;

const formContainer = styled.form`
  flex: 1;
  padding: 40px 24px;

  ${mobile(css`
    padding: 12px 8px 48px 8px;
  `)};

  h2 {
    ${setFontStyleV1("Head4", "Bold")};
    color: ${COLOR.primaryBlue};

    ${mobile(css`
      ${setFontStyleV1("Head6", "Bold")};
      color: ${COLOR.grayScale_800};
    `)};
  }

  .input-email {
    margin-top: 40px;

    ${mobile(css`
      margin-top: 16px;
    `)};
  }

  .input-text {
    margin-bottom: 16px;
  }

  .button {
    margin: 27px 0 26px 0;

    ${mobile(css`
      margin: 24px 0 21px 0;
    `)};
  }

  .find-password-header {
    display: flex;
    align-items: center;

    > .icon {
      cursor: pointer;
    }

    h2 {
      flex: 1;
      text-align: left;

      ${mobile(css`
        text-align: center;
        margin: 0 36px 0 4px;
      `)};
    }
  }

  .desc {
    margin-top: 40px;
    text-align: left;
    ${setFontStyleV1("SubHead3", "Bold")};
    color: ${COLOR.grayScale_700};

    ${mobile(css`
      margin-top: 36px;
    `)};
  }
`;

const quotationInProgressContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mobile(css`
    background-color: ${COLOR.primaryBlue_10};
    padding: 8px 0;
    margin: 12px -8px 0 -8px;
  `)};

  .icon {
    margin-right: 4px;
  }

  .quotation-in-progress-guide {
    ${setFontStyleV1("Head6", "Regular")};
    color: ${COLOR.grayScale_800};

    ${mobile(css`
      ${setFontStyleV1("Body8", "Regular")};
      color: ${COLOR.secondaryDeepBlue};
    `)};
  }
`;

const errorMsg = styled.div`
  text-align: left;
  ${setFontStyleV1("Body8")};
  color: ${COLOR.pointWarning};
`;

const loginOptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 3px;

  ${mobile(css`
    margin-top: 10px;
  `)};
`;

const registerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .register-guide {
    ${setFontStyleV1("SubHead4", "Regular")};
    color: ${COLOR.grayScale_700};
    margin-right: 10px;
  }

  .forgot-email-guide {
    ${setFontStyleV1("SubHead4", "Regular")};
    color: ${COLOR.grayScale_700};
  }

  > .contact-customer-center-button {
    padding: 8px;
    font-weight: bold;
    font-size: 12px;
    color: ${COLOR.primaryBlue};
    cursor: pointer;
  }
`;

const imageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 336px;
  height: 482px;
  background-color: ${COLOR.primaryBlue_10};
  padding: 8px;
  border-radius: 0px 16px 16px 0px;

  ${mobile(css`
    display: none;
  `)};

  img {
    width: 100%;
  }

  img.illust-login {
    height: 217px;
  }

  img.find-password-img {
    width: 280px;
    margin-right: 12px;
  }

  img.logo {
    width: 78px;
    margin: 42px 16px 0 0;
  }

  span.shipda-desc {
    margin: 4px 16px 24px 0;
    ${setFontStyleV1("Body8")};
    color: ${COLOR.grayScale_800};
    text-align: right;
  }
`;

export default {
  container,
  formContainer,
  quotationInProgressContainer,
  errorMsg,
  loginOptionContainer,
  registerContainer,
  imageContainer,
};
