import styled from "styled-components";

import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setElevationStyle } from "@sellernote/_sds-v2/src/styles/elevation";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const introBSection = styled.section`
  position: relative;
`;

const backgroundImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 220px;
`;

const textContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const title = styled.h2`
  color: ${COLOR.wh};
  ${setElevationStyle("level2", "text-shadow")};
  ${setFontStyle("Head5")};
`;

const description = styled.p`
  color: ${COLOR.wh_80};
  ${setFontStyle("SubHead5")};
`;

const searchBarWrapper = styled.div`
  background-color: ${COLOR.wh};
  height: 340px;

  .schedule-search-bar-for-mobile {
    position: absolute;
    top: 188px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;

    margin: 0 16px;
  }
`;

export default {
  introBSection,
  searchBarWrapper,
  backgroundImageWrapper,
  textContainer,
  title,
  description,
};
