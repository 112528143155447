import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div`
  min-width: 477px;

  ${mobile(css`
    min-width: 328px;
  `)}

  > .header {
    border-radius: 4px 4px 0 0;
    text-align: center;
    padding: 36px 24px 24px;
    background-color: ${COLOR.wh};

    > .title {
      margin-top: 16px;
      ${setFontStyle("SubHead3")};
      color: ${COLOR.point_400};
    }
  }

  > .body {
    padding: 24px 16px 32px;
    text-align: center;
    background-color: ${COLOR.grayScale_100};

    ${setFontStyle("Body3")};
    color: ${TEXT_COLOR.black_2};

    > strong {
      ${setFontStyle("SubHead4")};
      color: ${COLOR.primary_700};
    }

    > .divider {
      margin-bottom: 4px;
    }

    > .date {
      margin-top: 4px;
      ${setFontStyle("Body4")};
      color: ${COLOR.point_400};
    }

    > .signature {
      ${setFontStyle("SubHead3")};
      margin-top: 24px;
    }
  }
`;

export default {
  container,
};
