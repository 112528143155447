import { CSSProperties } from "react";
import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setElevationStyle } from "@sellernote/_sds-v2/src/styles/elevation";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div<{ width?: CSSProperties["width"] }>`
  position: relative;
  ${({ width }) =>
    css`
      width: ${typeof width === "number" ? `${width}px` : width};
    `}
`;

const loading = styled.div<{ width: string }>`
  ${setElevationStyle("level3")};
  z-index: 1;
  padding: 16px;
  ${({ width }) =>
    css`
      width: ${width};
    `}
  background: ${COLOR.wh};
`;

const emptySearchResult = styled.div<{ width: string }>`
  z-index: 1;
  ${setElevationStyle("level3")};

  display: flex;
  align-items: center;
  gap: 10px;
  padding: 18px 16px;
  background: ${COLOR.wh};
  ${({ width }) =>
    css`
      width: ${width};
    `}
  ${setFontStyle("SubHead4")};
  color: ${TEXT_COLOR.black_disabled};
`;

const optionList = styled.ul<{ width: string }>`
  z-index: 10;
  ${setElevationStyle("level3")};
  overflow-y: auto;
  max-height: 400px;
  background: ${COLOR.wh};
  ${({ width }) =>
    css`
      width: ${width};
    `}
`;

const highlightMatch = styled.mark`
  background-color: transparent;
  color: ${COLOR.primary_600};
`;

export default {
  container,
  loading,
  emptySearchResult,
  optionList,
  highlightMatch,
};
