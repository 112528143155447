import styled from "styled-components";

import { COLOR } from "../../styles/colors";
import { setFontStyle } from "../../styles/typography";

const container = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
`;

const manager = styled.div`
  display: flex;
  align-items: center;
`;

const name = styled.span`
  ${setFontStyle("SubHead3")};
`;

const role = styled.span`
  ${setFontStyle("Body3")};
  margin-left: 4px;
`;

const phone = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: ${COLOR.grayScale_800};
`;

const email = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: ${COLOR.grayScale_800};
  /** 이메일이 긴 경우가 있어 이렇게 처리(디자이너 지연님과 협의함) */
  white-space: nowrap;
`;

export default {
  container,
  manager,
  name,
  role,
  phone,
  email,
};
