import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const customerCard = styled.div<{ delay: number }>`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  background-color: ${COLOR.grayScale_50};
  animation-delay: ${({ delay }) => `${delay}s`};

  ${mobile(css`
    padding: 20px;
    gap: 6px;
  `)}
`;

const cardHeader = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const cardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  ${mobile(css`
    gap: 4px;
  `)}
`;

const company = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${TEXT_COLOR.black_3};

  .category {
    ${setFontStyle("SubHead4")};

    ${mobile(css`
      ${setFontStyle("SubHead5")};
    `)}
  }

  .name {
    ${setFontStyle("SubHead3")};

    ${mobile(css`
      ${setFontStyle("SubHead4")};
    `)}
  }
`;

const description = styled.p`
  ${setFontStyle("SubHead1")};
  color: ${TEXT_COLOR.black_2};

  ${mobile(css`
    ${setFontStyle("SubHead3")};
  `)}
`;

export default {
  customerCard,
  cardHeader,
  cardBody,
  company,
  description,
};
