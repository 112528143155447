import styled, { css } from "styled-components";

import { COLOR } from "../../../styles/colors";
import { setFontStyle } from "../../../styles/typography";

import { BtnAreaPadding, IconPosition, TextButtonTheme } from ".";

const container = styled.button<{
  theme: TextButtonTheme;
  fontSize: number;
  fontWeight?: number;
  iconPosition?: IconPosition;
  btnAreaPadding?: BtnAreaPadding;
  hasUnderline?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  ${setFontStyle("SubHead5")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  letter-spacing: -0.2px;
  line-height: 1;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  text-decoration: ${({ hasUnderline }) =>
    hasUnderline ? "underline" : "none"};
  text-decoration-color: initial;

  .icon {
    ${({ iconPosition }) => {
      if (iconPosition === "left")
        return css`
          margin-right: 4px;
        `;

      return css`
        margin-left: 4px;
      `;
    }}
  }

  ${({ btnAreaPadding }) => {
    const { left, right, top, bottom } = btnAreaPadding ?? {};

    return css`
      padding: ${`${top ?? 0}px ${right ?? 0}px ${bottom ?? 0}px ${
        left ?? 0
      }px`};
    `;
  }}

  ${({ theme }) => {
    switch (theme) {
      case "primary":
        return css`
          color: ${COLOR.primary_600};

          .icon svg path {
            fill: ${COLOR.primary_600};
          }

          &:hover:enabled {
            color: ${COLOR.primary_400};

            .icon svg path {
              fill: ${COLOR.primary_400};
            }
          }

          &:active:enabled {
            color: ${COLOR.primary_400};
            background: rgba(84, 130, 214, 0.16);

            .icon svg path {
              fill: ${COLOR.primary_400};
            }
          }

          &:disabled {
            color: ${COLOR.grayScale_300};
            cursor: not-allowed;

            .icon svg path {
              fill: ${COLOR.grayScale_300};
            }
          }
        `;

      case "gray":
        return css`
          color: ${COLOR.grayScale_800};

          .icon svg path {
            fill: ${COLOR.grayScale_800};
          }

          &:hover:enabled {
            color: ${COLOR.grayScale_700};

            .icon svg path {
              fill: ${COLOR.grayScale_700};
            }
          }

          &:active:enabled {
            color: ${COLOR.grayScale_700};
            background: rgba(141, 148, 160, 0.08);

            .icon svg path {
              fill: ${COLOR.grayScale_700};
            }
          }

          &:disabled {
            color: ${COLOR.grayScale_300};
            cursor: not-allowed;

            .icon svg path {
              fill: ${COLOR.grayScale_300};
            }
          }
        `;

      case "white":
        return css`
          color: ${COLOR.wh};

          .icon svg path {
            fill: ${COLOR.wh};
          }

          &:hover:enabled {
            color: ${COLOR.wh_80};

            .icon svg path {
              fill: ${COLOR.wh_80};
            }
          }

          &:active:enabled {
            color: ${COLOR.wh};
            background: rgba(255, 255, 255, 0.16);

            .icon svg path {
              fill: ${COLOR.wh};
            }
          }

          &:disabled {
            color: ${COLOR.grayScale_800};
            cursor: not-allowed;

            .icon svg path {
              fill: ${COLOR.grayScale_800};
            }
          }
        `;

      case "critical":
        return css`
          color: ${COLOR.error_400};

          .icon svg path {
            fill: ${COLOR.error_400};
          }

          &:hover:enabled {
            color: ${COLOR.error_400};

            .icon svg path {
              fill: ${COLOR.error_400};
            }
          }

          &:active:enabled {
            color: ${COLOR.error_400};
            background: rgba(84, 130, 214, 0.16);

            .icon svg path {
              fill: ${COLOR.error_400};
            }
          }

          &:disabled {
            color: ${COLOR.grayScale_300};
            cursor: not-allowed;

            .icon svg path {
              fill: ${COLOR.grayScale_300};
            }
          }
        `;
    }
  }}
`;

export default {
  container,
};
